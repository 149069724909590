#skills .title {
    height: 5rem !important;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
}

#skills .title h3 {
    padding-bottom: 5px;
}

#skills hr {
    width: 70%;
}

#skills .skill-container {
    position: relative;
    width: 70%;
    max-width: 70%;
}

#skills .skill-container .skill {
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    background-color: var(--red-color);
    color: #fff;
}

#skills .skill-container .skill p {
    max-width: 100%;
    text-align: center;
    margin: 0 !important;
}

#skills .swiper-button-next, #skills .swiper-button-prev {
    color: var(--primary-color) !important;
}

#skills .collapse-wrapper .view-more {
    cursor: pointer;
}