nav {
    min-height: 70px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.1rem;
}

nav img {
    max-height: 45px;
}

nav .nav-link {
    color: #fff !important;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}

.nav-spacer {
    position: relative;
    height: 70px;
    background-color: var(--primary-color);
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 !important;
}


.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
    background-color: #fff !important;
 }

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #fff !important;
}

nav .hamburger {
    border: 0 !important;
}