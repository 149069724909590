#jobs .image-container {
    position: relative;
    min-width: 20%;
    max-width: 20%;
    width: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}

#jobs .image-container img {
    max-height: 50px;
    max-width: 100%;
}

#jobs .jobs-head {
    position: relative;
}

#jobs .job-wrapper {
    position: relative;
    margin-left: 50px;
    margin: 50px;
}

#jobs .job-wrapper::before {
    content: "";
    position: absolute;
    top: -50px;
    bottom: 50px;
    left: -50px;
    width: 100%;
    background-color: var(--red-color);
    z-index: -1;
}

#jobs .jobs-head h2 {
    color: var(--primary-color);
    font-weight: bold;
}

#jobs .jobs-body {
    max-width: 35%;
}

#jobs button a {
    text-decoration: none;
    color: inherit !important;
    font-weight: inherit !important;
    font-family: inherit !important;
}

@media (max-width: 765px) {
    #jobs .jobs-head .job-wrapper {
        margin: 50px 0 0 0 !important;
    }

    #jobs .jobs-head .job-wrapper::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translate(-50%, 0);
    }
}