footer {
    background-color: var(--primary-color);
}

footer .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
}

footer .logo-container img {
    width: 300px;
    object-fit: contain;
    cursor: pointer;
}

footer .link-container ul {
    padding-left: 0 !important;
}


footer .link-container .nav-link {
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
}

footer .social-container .nav-link {
    color: #fff;
}

@media (max-width: 992px) {
    footer .logo-container img {
       
    }
}