@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700;900&family=Montserrat:wght@100&family=Nixie+One&family=Open+Sans:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

section {
  max-width: 100vw;
  overflow: hidden;
}

:root {
  --primary-color: #0D0D0D;
  --red-color: #bb0d2e;
}

.no-scroll {margin: 0 !important; overflow: hidden !important}

.primary-button {
  position: relative;
  width: 250px;
  height: 60px;
  border-radius: 30px;
  border: 3px solid #fff;
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  font-size: 1.7rem;
  transition: ease-out 0.3s;
  z-index: 1;
}

.primary-button:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.primary-button::before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 0;
  opacity: 0;
  content: "";
  border-radius: 30px;
  background-color: #fff;
}

.primary-button:hover::before {
  transition: 0.5 all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}

.secondary-button {
  position: relative;
  width: 185px;
  height: 60px;
  border: 4px solid var(--red-color);
  background-color: transparent;
  color: black;
  font-weight: bold;
  font-size: 1.3rem;
  transition: ease-out 0.3s;
  z-index: 1;
}

.secondary-button:hover {
  cursor: pointer;
  color: #fff;
  border-color: var(--red-color) !important;
}

.secondary-button::before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: var(--red-color);
}

.secondary-button:hover::before {
  transition: 0.5 all ease;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: -1;
}

.opacity-fade {
  animation: disappear 1s ease-out forwards;
  display: block;
  opacity: 1;
  padding: 0 !important;
  margin: 0 !important;
}

.scroll-anchor {
  position: absolute;
  height: 1px;
  width: 1px;
}


@keyframes disappear {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
