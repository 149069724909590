#header {
    position: relative;
    background-color: #0D0D0D;
    color: #fff;
}


#header img {
    width: 350px;
    object-fit: contain;
}

#header h1 {
    font-family: 'Nixie One', cursive;
    font-weight: bold;
}

#header h2 {
    font-family: 'Montserrat', sans-serif;
}

#header .quote-container p {
    position: relative;
    max-width: 80%;
    /* font-family: 'Montserrat', sans-serif; */
    font-weight: bold;
}

#header .quote-container .display-1 {
    font-family: serif;
}


@media (max-width: 995px) {

    #header .container:nth-child(1) .col {
        padding-bottom: 3rem;
    }

    #header .col {
        min-width: 100%;
    }

    #header .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
    }

    #header .wrapper h1, #header .wrapper h2 {
    text-align: center;
    }

    #header .image-container {
        justify-content: center !important;
    }

    /* Jobs */

    #jobs .jobs-container {
        flex-direction: column-reverse;
    }

    #jobs .jobs-body {
        margin: 0 0 3rem 0 !important;
        min-width: 100%;
    }

    #jobs .jobs-head { 
        min-width: 100%;
        margin-right: 0 !important;
    }
}

@media (max-width: 992px) {
    #header .header-head .image-container {
        padding: 0 !important;
    }
    #header .header-head .image-container img {
        max-width: 85%;
    }
}

